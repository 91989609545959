import React from 'react'
import LineupModule from './Lineup.module.css'
import LineupCard from './blocks/LineupCard';

const Lineup = () => {
    return (
        <section className={LineupModule.lineup}>
            <h2 className={LineupModule.h2}>LINE UP</h2>
            <div className={LineupModule.container}>
                <LineupCard url="https://lumitsa.official.ec/items/68100969" />
                <LineupCard url="https://lumitsa.official.ec/items/68101099" />
                <LineupCard url="https://lumitsa.official.ec/items/68878136" />
            </div>
        </section>
    )
}

export default Lineup