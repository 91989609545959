import React from 'react'
import UsecaseModule from '../Usecase.module.css';

const FarmCard = (content) => {
    return (
        <div className={UsecaseModule.card}>
            <div className={UsecaseModule.card__img}>
                <img src={content.imageSrc} alt={content.alt} />
            </div>
            <div className={UsecaseModule.card__tx}>
                <p>{content.text}</p>
            </div>
        </div>
    )
}

export default FarmCard