import React from 'react'
import feature01 from '../assets/images/feature01.png';
import feature02 from '../assets/images/feature02.png';
import FeatureBlock from './blocks/FeatureBlock';
import FeatureModule from './Feature.module.css';

const Feature = () => {
    return (
        <section className={FeatureModule.feature}>
            <h2 className={FeatureModule.h2}>FEATURE</h2>
            <FeatureBlock imageSrc={feature01} alt={'Lumitsaの製品1'} h3={'非加熱・無添加'} text={'はちみつの健康・美容効果を最大限活かしたプロダクトづくりにこだわっています。LUMITSÄの世界観を通じて、豊かな生活を提案します。'} />
            <FeatureBlock imageSrc={feature02} alt={'Lumitsaの製品2'} h3={["はちみつは、ビタミン、ミネラルが", <br key={1} />, "豊富なスーパーフード"]} text={["甘味料としてはもちろん、たくさんの栄養素をバランスよく、", <br className={FeatureModule.pc_only} key={2} />, "効率的に取り入れることができます。ひと匙のはちみつが、日々の活力に。"]} />
        </section>
    )
}

export default Feature