import React from 'react'
import InstagramModule from '../Instagram.module.css'

const InstagramCard = (content) => {
    return (
        <div className={InstagramModule.card}>
            <a href={content.url} target="_blank" rel="noopener noreferrer">
                <img src={content.imageSrc} alt={content.alt} />
            </a>
        </div>
    )
}

export default InstagramCard