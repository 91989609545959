import React from 'react'
import { useInView } from "react-intersection-observer"

const ScrollEffect = ({
    children,
    rootMargin = "0",
    animation,
    startClass = "",
    triggerOnce = false,
}) => {
    const { ref, inView } = useInView({
        rootMargin: rootMargin,
        triggerOnce: triggerOnce
    });

    return (
        <div ref={ref} className={inView ? animation : startClass}>{children}</div>
    )
}

export default ScrollEffect