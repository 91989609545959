import './App.css';
import headerLogo from './assets/images/header_logo.png';
import Footer from './components/Footer';
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Pages/Home';

function App() {
  return (
    <Router>
      <div className="App">
        <Header imageSrc={headerLogo} />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
