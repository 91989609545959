import React from 'react'
import FooterModule from './Footer.module.css'

const Footer = () => {
    return (
        <footer className={FooterModule.footer}>
            <p>Copyright ©︎ 2022 fullout All rights reserved.</p>
        </footer>
    )
}

export default Footer