import React from 'react'
import farm01 from '../assets/images/farm01.png';
import farm02 from '../assets/images/farm02.png';
import farm03 from '../assets/images/farm03.png';
import FarmBg from '../assets/images/farm_bg.png';
import FarmModule from './Farm.module.css';
import styled from "styled-components";
import media from "styled-media-query";

const Section = styled.section`
    position: relative;
    padding: 100px 0;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 162 193 / 70%);f
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${FarmBg});
        background-size : cover;
        background-repeat: no-repeat;
        z-index : -1;
    }
    ${media.lessThan("medium")`
        padding-bottom: 70px;
        &::before {
            height: 310px;
        }
        &::after {
            height: 310px;
        }
    `}
`;

const Farm = () => {
    return (
        <Section>
            <div className={FarmModule.container}>
                <div className={FarmModule.imgs}>
                    <div className={FarmModule.img}>
                        <img src={farm03} alt="花から蜜を取るみつばち" />
                    </div>
                    <div className={FarmModule.img}>
                        <img src={farm02} alt="みつばち" />
                    </div>
                </div>
                <div className={FarmModule.tx}>
                    <h2 className={FarmModule.h2}>MOYA BEE FARM</h2>
                    <div className={FarmModule.p}>
                        <p>MOYA BEE FARMは、その土地ならではの資源・特長を活かした事業を通じて、地域の活性化に貢献します。</p>
                        <p>青森市は蜜源となる木花ときれいな水流に恵まれた、国内有数のはちみつ生産地。近年、地球環境の維持・向上の必要性が高まる中、生態系の維持に不可欠であるミツバチがその存在感を高めていることに着目し、この地に養蜂場をオープンしました。</p>
                        <p>これまで培ってきた組織開発の知見を活かし、事業活動を通じて地域の活性化、ひいてはこれらの恵まれた資源を活かし、地域の成長を支えていく人材を輩出することを目指します。</p>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Farm