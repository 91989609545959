import React, { useState, useEffect } from 'react'
import axios from "axios";
import InstagramModule from './Instagram.module.css'
import InstagramCard from './blocks/InstagramCard';
import { isMobile } from 'react-device-detect';

const Instagram = () => {
    const [posted, setPosted] = useState([])

    const access_token = process.env.REACT_APP_IG_TOKEN
    const user_id = process.env.REACT_APP_IG_USER_ID
    const get_count = isMobile ? 6 : 5
    const query = 'name,media.limit(' + get_count + '){caption,media_url,thumbnail_url,media_type,permalink,timestamp,username,comments_count}';
    const url = 'https://graph.facebook.com/v15.0/' + user_id + '?fields=' + query + '&access_token=' + access_token;

    useEffect(() => {
        axios
            .get(
                url
            )
            .then((res) => {
                setPosted(res.data.media.data);
            });
    }, []);

    if (!posted) return null;

    return (
        <section className={InstagramModule.instagram}>
            <h2 className={InstagramModule.h2}>INSTAGRAM</h2>
            <div className={InstagramModule.container}>
                {posted.map((output, index) => {
                    console.log(output);
                    if (output.media_type === 'VIDEO') {
                        return (
                            <InstagramCard imageSrc={output.thumbnail_url} alt={output.caption} url={output.permalink} key={index} />
                        );
                    } else {
                        return (
                            <InstagramCard imageSrc={output.media_url} alt={output.caption} url={output.permalink} key={index} />
                        );
                    }
                })}
                <a href="https://www.instagram.com/lumitsa_official/" className={InstagramModule.more} target="_blank" rel="noreferrer noopener">more...</a>
            </div>
        </section>
    )
}

export default Instagram