import React from 'react'
import ContactModule from './Contact.module.css';

const Contact = () => {
    return (
        <section className={ContactModule.contact}>
            <h2 className={ContactModule.h2}>CONTACT</h2>
            <div className={ContactModule.text}>
                <p>フォームよりお問い合わせ下さい。</p>
                <p>本内容は<a href='https://full-out.co.jp/privacy-policy/' target='blank'>プライバシーポリシー</a>に沿って管理し、<br />お客様の承諾なく、第三者に開示・提供することはございません。</p>
            </div>
            <a href="https://form.lumitsa.jp/" className={ContactModule.more}>お問い合わせはこちら</a>
        </section>
    )
}

export default Contact