import React from 'react'
import GuideModule from './Guide.module.css';

const Guide = () => {
    return (
        <section className={GuideModule.guide}>
            <div className={GuideModule.container}>
                <h2 className={GuideModule.h2}>SHOP GUIDE</h2>
                <dl className={GuideModule.dl}>
                    <dt>販売価格について</dt>
                    <dd>販売価格は、税込み表記となっております。<br />また、別途配送料が掛かる場合もございます。配送料に関しては商品詳細ページをご確認ください。</dd>
                    <dt>お支払いについて</dt>
                    <dd>クレジットカード、銀行振り込み等、各種お支払方法がご利用いただけます。</dd>
                    <dt>商品の配送について</dt>
                    <dd>
                        <ul>
                            <li>お届け日については、商品詳細ページをご確認ください。</li>
                            <li>原則、ゆうパックでの配送となります。¥10,000以上のご注文で国内送料が無料になります。</li>
                        </ul>
                    </dd>
                    <dt>返品・交換について</dt>
                    <dd>
                        <ul>
                            <li>商品に欠陥がある場合をのぞき、基本的には返品には応じません。</li>
                            <li>配送には細心の注意をはらっておりますが、万が一不良商品、もしくはご注文された商品と異なる商品が届いた場合は、速やかに交換の対応をさせていただきます。完売等により商品がご用意できない場合には、返金処理とさせていただきます。</li>
                            <li>返品・交換につきましては、原則、お荷物を受け取られてから5日以内にご連絡いただいた場合に限りご対応いたします。</li>
                        </ul>
                    </dd>
                </dl>
            </div>
        </section>
    )
}

export default Guide