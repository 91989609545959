import React from 'react'
import FeatureModule from "../Feature.module.css";

const FeatureBlock = (content) => {
    return (
        <div className={FeatureModule.container}>
            <div className={FeatureModule.img}>
                <img src={content.imageSrc} alt={content.alt} />
            </div>
            <div className={FeatureModule.tx}>
                <h3>{content.h3}</h3>
                <p>{content.text}</p>
            </div>
        </div>
    )
}

export default FeatureBlock