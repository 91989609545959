import React from 'react'
import AboutModule from "../About.module.css";

const AboutBlock = (content) => {

    return (
        <div className={AboutModule.container}>
            <div className={AboutModule.content}>
                <div className={AboutModule.img}>
                    <div className={AboutModule.whiteWrap}>
                        <img src={content.imageSrc} alt={content.alt} />
                    </div>
                    {content.text}
                </div>
                <h3 className={AboutModule.h3}>{content.h3}</h3>
            </div>
        </div >
    )
}

export default AboutBlock;