import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.svg';
import hero01 from '../../assets/images/hero01.png';
import hero02 from '../../assets/images/hero02.png';
import hero03 from '../../assets/images/hero03.png';
import heroSp01 from '../../assets/images/hero01_sp.png';
import heroSp02 from '../../assets/images/hero02_sp.png';
import heroSp03 from '../../assets/images/hero03_sp.png';
import opening_image from '../../assets/images/opening_image.svg';
import About from '../About'
import Campany from '../Campany'
import Contact from '../Contact'
import Farm from '../Farm'
import Feature from '../Feature'
import Guide from '../Guide'
import Hero from '../Hero'
import Instagram from '../Instagram'
import Lineup from '../Lineup'
import Usecase from '../Usecase'
import ScrollEffect from '../ScrollEffect';
import HomeModule from './Home.module.css'

const Home = () => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false);
        }, 3 * 1000)
    }, [])

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className={HomeModule.fadeIn}>
                    <div className={HomeModule.opening_image}>
                        <img src={opening_image} alt="LUMITSA" />
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            <Hero hero01={hero01} hero02={hero02} hero03={hero03} heroSp01={heroSp01} heroSp02={heroSp02} heroSp03={heroSp03} imageSrc={logo} />
            <ScrollEffect children={<About />} animation="fadeIn" rootMargin="-300px" triggerOnce />
            <ScrollEffect children={<Feature />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Lineup />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Farm />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Usecase />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Instagram />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Contact />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Guide />} animation="fadeIn" rootMargin="0px" triggerOnce />
            <ScrollEffect children={<Campany />} animation="fadeIn" rootMargin="0px" triggerOnce />
        </>
    )
}

export default Home