import React from 'react'
import CampanyModule from './Campany.module.css'

const Campany = () => {
    return (
        <section className={CampanyModule.campany}>
            <div className={CampanyModule.container}>
                <h3 className={CampanyModule.h3}>株式会社fullout</h3>
                <div className={CampanyModule.address}>
                    <p>〒160-0023</p>
                    <p>東京都新宿区西新宿1-20-3 西新宿高木ビル7階</p>
                    <p className={CampanyModule.url}>URL：<a href="https://full-out.co.jp/" target="_blank" rel="noopener noreferrer">https://full-out.co.jp/</a></p>
                </div>
            </div>
        </section>
    )
}

export default Campany