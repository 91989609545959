import React from 'react'
import LineupModule from '../Lineup.module.css'

const LineupCard = (content) => {

    const url = content.url + '/widget/large';

    return (
        <div className={LineupModule.card}>
            <iframe frameBorder={0} height="480" width="320" src={url} title="Lumitsaの製品" ></iframe>
        </div>
    )
}

export default LineupCard