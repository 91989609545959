import React from 'react'
import { Link } from 'react-router-dom'
import HeaderModule from "./Header.module.css";

const Header = ({ imageSrc }) => {
    return (
        <header className={HeaderModule.header}>
            <div className={HeaderModule.logo}>
                <Link to="/"><img src={imageSrc} alt="LUMITSA" /></Link>
            </div>
        </header >
    )
}

export default Header