import React from 'react'
import usecase01 from '../assets/images/usecase01.png';
import usecase02 from '../assets/images/usecase02.png';
import usecase03 from '../assets/images/usecase03.png';
import UsecaseModule from './Usecase.module.css';
import UsecaseCard from './blocks/UsecaseCard';

const Usecase = () => {
    return (
        <section className={UsecaseModule.usecase}>
            <div className={UsecaseModule.container}>
                <UsecaseCard imageSrc={usecase01} alt={'手にヒラに乗るはちみつ'} text={'はちみつは、花の種類によって味も香りも様々。記憶に残る特別な一品を、大切な人へのプレゼントに。'} />
                <UsecaseCard imageSrc={usecase02} alt={'紅茶とはちみつ'} text={'免疫力をアップしたい今こそ、はちみつを毎日の習慣に。気分や用途で選ぶのも、楽しみのひとつ。'} />
                <UsecaseCard imageSrc={usecase03} alt={'ドライフラワーとはちみつ'} text={'LUMITSÄのはちみつと、豊かな時間を過ごす。'} />
            </div>
        </section>
    )
}

export default Usecase