import React from 'react';
import MainVisualBgImageSrc from '../assets/images/mainvisual_logo.png';
import HeroModule from "./Hero.module.css";
import styled from "styled-components";
import media from "styled-media-query";
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';

const MainVisualTx = styled.div`
    position: absolute;
    top: 50%;
    left: 82px;
    transform: translateY(-50%);
    z-index: 5;
    &::before {
        content: '';
        background-image: url(${MainVisualBgImageSrc});
        background-size: cover;
        background-repeat: no-repeat;
        width: 180px;
        height: 247px;
        position: absolute;
        top: -100px;
        left: -60px;
        z-index: -1;
    }
    img {
        position: relative;
        width: 263px;
    }
    h2 {
        margin: 25px 0 50px 0;
        font-size: 2.25rem;
        font-family: 'Zen Kaku Gothic New', sans-serif;
    }
    p {
        font-size: 1.25rem;
        color: #7C8C8F;
        margin: 0;
    }
    ${media.lessThan("medium")`
        top: unset;
        bottom: 100px;
        left: 20px;
        transform: unset;
        &::before {
            width: 104px;
            height: 147px;
            top: -80px;
            left: -10px;
        }
        img {
            width: 183px;
        }
        h2 {
            font-size: 1.25rem;
            margin: 8px 0 20px 0;
        }
        p {
            font-size: .75rem;
        }
    `}
`;

const Hero = ({ hero01, hero02, hero03, heroSp01, heroSp02, heroSp03, imageSrc }) => {
    if (isMobile) {
        return (
            <section className={HeroModule.hero}>
                <div className={HeroModule.mainVisual}>
                    <div className={HeroModule.mainVisual__img}>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, EffectFade]}
                            slidesPerView={1}
                            navigation
                            speed={1500}
                            pagination={{ clickable: true }}
                            effect={"fade"}
                            autoplay={{
                                "delay": 5000,
                                "disableOnInteraction": false
                            }}
                            fadeEffect={{
                                crossFade: true
                            }}
                        >
                            <MobileView>
                                <SwiperSlide><div className={HeroModule.whiteWrap}><img src={heroSp01} alt="Lumitsaの製品" /></div></SwiperSlide>
                                <SwiperSlide><div className={HeroModule.whiteWrap}><img src={heroSp02} alt="Lumitsaの製品" /></div></SwiperSlide>
                                <SwiperSlide><div className={HeroModule.whiteWrap}><img src={heroSp03} alt="Lumitsaの製品" /></div></SwiperSlide>
                            </MobileView>
                        </Swiper>
                    </div>
                    <MainVisualTx>
                        <img src={imageSrc} alt="Lumitsa" className={HeroModule.logo__image} />
                        <h2>Japanese Honey Brand</h2>
                        <p className={HeroModule.copy}>自己実現を叶えるライフスタイルブランド</p>
                    </MainVisualTx>
                    <div className={HeroModule.mainVisual__cta}>
                        <a href="https://lumitsa.official.ec/" target="_blank" rel="noreferrer">Online Shop</a>
                    </div>
                </div>
            </section >
        )
    } else {
        return (
            <section className={HeroModule.hero}>
                <div className={HeroModule.mainVisual}>
                    <div className={HeroModule.mainVisual__img}>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, EffectFade]}
                            slidesPerView={1}
                            navigation
                            speed={1500}
                            pagination={{ clickable: true }}
                            effect={"fade"}
                            autoplay={{
                                "delay": 5000,
                                "disableOnInteraction": false
                            }}
                            fadeEffect={{
                                crossFade: true
                            }}
                        >
                            <BrowserView>
                                <SwiperSlide><div className={HeroModule.whiteWrap}><img src={hero01} alt="Lumitsaの製品" /></div></SwiperSlide>
                                <SwiperSlide><div className={HeroModule.whiteWrap}><img src={hero02} alt="Lumitsaの製品" /></div></SwiperSlide>
                                <SwiperSlide><div className={HeroModule.whiteWrap}><img src={hero03} alt="Lumitsaの製品" /></div></SwiperSlide>
                            </BrowserView>
                        </Swiper>
                    </div>
                    <MainVisualTx>
                        <img src={imageSrc} alt="Lumitsa" className={HeroModule.logo__image} />
                        <h2>Japanese Honey Brand</h2>
                        <p className={HeroModule.copy}>自己実現を叶えるライフスタイルブランド</p>
                    </MainVisualTx>
                    <div className={HeroModule.mainVisual__cta}>
                        <a href="https://lumitsa.official.ec/" target="_blank" rel="noreferrer">Online Shop</a>
                    </div>
                </div>
            </section >
        )
    }
}

export default Hero;