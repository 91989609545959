import React from 'react';
import about01 from '../assets/images/about01.png';
import about02 from '../assets/images/about02.png';
import about03 from '../assets/images/about03.png';
import dividerImage from '../assets/images/divider.png';
import dividerImage02 from '../assets/images/divider02.png';
import AboutModule from "./About.module.css";
import AboutBlock from './blocks/AboutBlock';
import styled from "styled-components";
import media from "styled-media-query";

const Divider = styled.div`
    position: relative;
    width: 100%;
    height: 200px;
    background-color: rgb(0 162 193 / 70%);
    margin-top: 215px;
    &::before {
        content: '';
        background-image: url(${dividerImage02});
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    ${media.lessThan("medium")`
        height : 82px;
        margin-top: 100px;
    `}
`;

const About = () => {

    return (
        <section className={AboutModule.about}>
            <h2 className={AboutModule.h2}>ABOUT</h2>
            <AboutBlock imageSrc={about03} alt={"雲谷の風景"} h3={["世界一", <span className={AboutModule.cyan} key="1">雪</span>, "が積もる都市", <br key="br" />, "青森市の", <span className={AboutModule.cyan} key="3">雲谷</span>]} text={[<p key="p3">LUMITSÄのはちみつは、世界一雪が積もる都市、青森市の雲谷（モヤ）エリアにある自社養蜂場で生産されています。</p>, <p key="p4">長い冬を耐え抜く生命のパワーが、はちみつにも詰まっています。</p>]} />
            <AboutBlock imageSrc={about02} alt={"雲谷のおいしい水"} h3={["日本一おいしい", <span className={AboutModule.cyan} key="2">水</span>, "が生み出す", <br key="4" />, "高品質なはちみつ"]} text={[<p key="p1">蜜源となる木花が豊富で、'日本一おいしい水'が多くの生命を支える唯一無二の環境が、高品質なはちみつを生み出します。</p>, <p key="p2">生態系の保護活動にも力を入れ、自然のおいしさと栄養をお客様にお届けします。</p>]} />
            <Divider></Divider>
        </section >
    );
}

export default About